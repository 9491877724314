<template>
  <vue-ueditor-wrap
      ref="editorRef"
      editor-id="rf-editor"
      name="content"
      v-model="valueHtml"
      :config="editorConfig"
      :class="isDisabled ? 'editor-disabled' : ''"
      @ready="ready"
      @onChange="handleChange"
  ></vue-ueditor-wrap>
</template>
<script >
import {VueUeditorWrap} from 'vue-ueditor-wrap';
import {onMounted, ref} from 'vue';
export default {
  name:"UEditor",
  components: { VueUeditorWrap },
  props:{
    modelValue:String
  },

  setup(props,context){
    const editorRef = ref(null);
    const isDisabled = false; // 是否只读
    const editorConfig= ref({
      // 编辑器不自动被内容撑高
      autoHeightEnabled: false,
      // 初始容器高度2
      initialFrameHeight: 500,
      // 初始容器宽度
      initialFrameWidth: "100%",
      // 上传文件接口
      serverUrl:process.env.VUE_APP_API_BASE_URL+"/UEditor/index",
      // 访问 UEditor 静态资源的根路径publc里面的名称
      UEDITOR_HOME_URL: "/UEditor/",
      enableAutoSave: true,
      toolbars: [
        [
          "source",
          "undo",
          "redo",
          "|",
          "bold",
          "italic",
          "underline",
          "fontborder",
          "strikethrough",
          "superscript",
          "subscript",
          "removeformat",
          "formatmatch",
          "autotypeset",
          "blockquote",
          "pasteplain",
          "|",
          "forecolor",
          "backcolor",
          "insertorderedlist",
          "insertunorderedlist",
          "selectall",
          "cleardoc",
          "|",
          "rowspacingtop",
          "rowspacingbottom",
          "lineheight",
          "|",
          "customstyle",
          "paragraph",
          "fontfamily",
          "fontsize",
          "|",
          "directionalityltr",
          "directionalityrtl",
          "indent",
          "|",
          "justifyleft",
          "justifycenter",
          "justifyright",
          "justifyjustify",
          "|",
          "touppercase",
          "tolowercase",
          "|",
          "link",
          "unlink",
          "|",
          "imagenone",
          "imageleft",
          "imageright",
          "imagecenter",
          "|",
          "simpleupload",
          "insertimage",
          "insertvideo",
          "attachment",
          "pagebreak",
          "background",
          "|",
          "horizontal",
          "date",
          "time",
          "spechars",
          "|",
          "inserttable",
          "deletetable",
          "insertparagraphbeforetable",
          "insertrow",
          "deleterow",
          "insertcol",
          "deletecol",
          "mergecells",
          "mergeright",
          "mergedown",
          "splittocells",
          "splittorows",
          "splittocols",
          "|",
          "print",
          "preview",
          "searchreplace"
        ]
      ]
    });
    const ready = (editorInstance) => {
      if (isDisabled) {
        // 不是编辑状态，禁用编辑，隐藏工具栏
        editorInstance.body.contentEditable = false;
      }
      editorInstance.addListener("contentChange",()=>{
        context.emit("update:modelValue", editorInstance.getContent());
      });
    };

    const handleChange = (e) => {
      console.log(e)
    };
// 初始化编辑器的值
    const valueHtml = ref("");
    onMounted(() => {
      setTimeout(() => {
        valueHtml.value = props.modelValue;
      }, 1000);
    });
    // const initEditor = () => {
    //   console.log(props.modelValue)
    //   valueHtml.value = props.modelValue;
    // };
    return{
      editorRef,
      editorConfig,
      ready,
      //initEditor,
      isDisabled,
      valueHtml,
      handleChange,

    }
  }
}
</script>

<style scoped lang="less">

</style>
